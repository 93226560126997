.sliderContent {
  height: calc(100vh - 125px);
  border-right: 1px solid rgba(151, 151, 151, .1);
  overflow-y: auto;
  position: sticky;
  top: 125px;
  padding-bottom: 100px;

  .filtersIcon {
    width: 25px;
    height: 25px;

    img {
      display: block;
      width: 25px;
      height: 25px;
    }
  }

  .smallIcon {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .projectSliderList {
    width: 100%;
    height: 60px;
    padding-left: 29px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(151, 151, 151, .1);

    .filtersName {
      width: auto;
      font-size: 20px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #000000;
    }

    .buyNowName {
      width: auto;
      font-size: 15px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #000000;
    }
  }

  .filterlist {
    padding-right: 26px;
  }

  .buyNowlist {
    padding-right: 32px;
  }

  .selectListcom {
    width: 100%;
    height: auto;
    padding: 20px 32px 10px 29px;
    border-bottom: 1px solid rgba(151, 151, 151, .1);

    .groupTitle {
      width: 100%;
      height: 18px;
      font-size: 15px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #000000;
      line-height: 18px;
      margin-bottom: 19px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .selectopen {
        display: block;
        width: 16px;
        height: 16px;
      }
    }

    .inputGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;

      .priceInput {
        width: 80px;
        height: 40px;
        border-radius: 15px;
        border: 1px solid rgba(151, 151, 151, .1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000;
        text-align: center;
        background: transparent;

        &::placeholder {
          font-size: 12px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #CBC5C5;
        }
      }

      .line {
        margin: 0 7px;
        color: #000;
        font-size: 12px;
      }

      .selectList {
        width: 80px;
        height: 40px;
        border-radius: 15px;
        border: 1px solid rgba(151, 151, 151, .1);
        margin-left: 19px;
        display: flex;
        align-items: center;
        // padding: 0 6px;
        background: transparent;

        .css-1s2u09g-control,
        .css-1pahdxg-control {
          border: 0;
          background: transparent;
          padding: 0;

          &.active {
            border: 0;
          }
        }

        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
          padding: 2px;
        }

        .css-6j8wv5-Input {
          min-width: 20px;
        }
      }
    }

    .applyButton {
      width: 180px;
      height: 40px;
      background: #738BD8;
      border-radius: 10px;
      line-height: 40px;
      text-align: center;
      font-size: 15px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #FFFFFF;
      cursor: pointer;
    }

    .moudleContent {}

    .searchGroup {
      width: 100%;
      height: 40px;
      border-radius: 15px;
      border: 1px solid rgba(151, 151, 151, .1);
      display: flex;
      align-items: center;
      padding-left: 19px;

      .searchicon {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }

      .selectSearch {
        flex: 1;
        height: 100%;
        background: transparent;
        border: 0;
        font-size: 12px;
        color: #000;
      }
    }

    .listGroup {
      max-height: 118px;
      overflow-y: auto;
      padding: 19px 0;
    }

    .selectList {
      width: 100%;
      height: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      font-size: 12px;
      font-family: Roboto-Bold, Roboto;
      font-weight: 300;
      color: #000000;

      .checkoutbox {
        width: auto;
        height: 100%;
        // overflow: hidden;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .plotGroup {
      display: flex;
      justify-content: center;

      .plotInputGroup {
        width: 120px;
        height: auto;
        text-align: center;
        font-size: 12px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #CBC5C5;

        .plotinput {
          width: 120px;
          height: 40px;
          border-radius: 15px;
          border: 1px solid rgba(151, 151, 151, .1);
          line-height: 38px;
          margin-bottom: 12px;
          background: transparent;
          text-align: center;
          color: #CBC5C5;

          &::placeholder {
            color: #CBC5C5;
          }
        }

        p {
          width: 100%;
          height: 14px;
          line-height: 14px;
        }
      }

      .plotline {
        flex: 1;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
      }
    }
  }
}

.bgSlider {
  width: 340px;
}

.smallSlider {
  width: 60px;
}
