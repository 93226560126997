.discovery {
  width: 100%;
  height: auto;
  margin-top: 52px;

  h2 {
    width: auto;
    font-size: .50rem;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #000000;
    line-height: .58rem;
    margin-bottom: .51rem;
    padding-left: 1.4rem;
  }

  .discovery-listcom {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: .25rem;

    .discovery-icon {
      width: 1.53rem;
      height: inherit;
      display: flex;
      justify-content: center;
      padding-top: 1.75rem;

      .disimgcom {
        display: block;
        width: .50rem;
        height: .50rem;
      }
    }

    .listContent {
      width: 16.15rem;
      height: inherit;
      overflow: hidden;
    }

    .twolist {
      margin-left: 1.53rem;
    }

    .showlist {
      width: auto;
      display: flex;
      position: relative;

      .discover-content {
        cursor: pointer;
        flex-shrink: 0;
        width: 3.70rem;
        // min-width: 250px;
        height: auto;
        margin-right: .45rem;
        padding-top: .16rem;
        position: relative;
        box-shadow: 0px 8px 15px 0px rgba(149, 156, 182, 0.15);
        border-radius: .10rem;
        border: 1px solid #EAEBF0;
        background: #fff;
        padding-bottom: .2rem;

        .detaGroup {
          width: auto;
          height: auto;
          background: #FFFFFF;
          box-shadow: 0px 8px 15px 0px rgba(149, 156, 182, 0.15);
          border-radius: .10rem;
          padding: .16rem .16rem .08rem;
          position: absolute;
          top: .3rem;
          left: .2rem;

          .rqi {
            width: auto;
            height: 29px;
            font-size: 25px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #000000;
            line-height: 29px;
          }

          .mouth {
            width: auto;
            height: 16px;
            font-size: 14px;
            font-family: Roboto-Light, Roboto;
            font-weight: 300;
            color: #000000;
            line-height: 16px;
            text-align: center;
          }
        }

        .smallLogo {
          display: block;
          width: .25rem;
          height: .25rem;
          position: absolute;
          top: 3.19rem;
          left: .2rem;
        }

        .mLogo {
          display: flex;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          position: absolute;
          top: 3.14rem;
          left: 1.24rem;
          justify-content: center;
          align-items: center;
          background: #fff;

          .logo {
            display: block;
            width: .94rem;
            height: .94rem;
            border-radius: 50%;
          }
        }


        .fmall {
          width: 3.50rem;
          height: 3.5rem;
          overflow: hidden;
          margin: 0 auto;
        }

        .fmImg {
          object-fit: cover;
        }

        .title {
          display: flex;
          justify-content: center;
          width: 100%;
          height: .24rem;
          margin-top: .50rem;
          font-size: .20rem;
          font-family: Roboto-BlackItalic, Roboto;
          font-weight: normal;
          color: #000000;
          line-height: .24rem;

          .rz {
            display: block;
            width: .20rem;
            height: .20rem;
            margin-left: 8px;
          }
        }

        .footerInfo {
          width: 3.35rem;
          height: .70rem;
          margin: .17rem auto 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid rgba(210, 204, 204, .5);

          .date {
            font-size: 12px;
            font-family: Roboto-Light, Roboto;
            font-weight: 300;
            color: #000000;
          }

          .but {
            width: 60px;
            height: 30px;
            background: #8636CD;
            border-radius: 15px;
            text-align: center;
            line-height: 30px;
            font-size: 12px;
            font-family: Roboto-Light, Roboto;
            font-weight: 300;
            color: #FFFFFF;
          }
        }
      }
    }

    .listtwo {
      margin-left: 1.4rem;
    }
  }
}
