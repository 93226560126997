.collectionMainInfo {
  display: flex;

  .imgGroup {
    width: 450px;
    height: 450px;
    background: #ffffff;
    box-shadow: 0px 8px 15px 0px rgba(149, 156, 182, 0.15);
    border-radius: 10px;
    border: 1px solid #eaebf0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 269px;

    .collectionCover {
      display: block;
      width: 420px;
      height: 420px;
    }
  }

  .collectionInfo {
    flex: 1;

    .title {
      display: flex;
      align-items: center;
      width: auto;
      height: 25px;
      font-size: 20px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #8636cd;
      margin-bottom: 13px;
      cursor: pointer;

      .rzoneImg {
        display: block;
        width: 25px;
        height: 25px;
        margin-left: 8px;
      }
    }

    .collecDesc {
      width: auto;
      height: 29px;
      font-size: 25px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #000000;
      line-height: 29px;
      // margin-top: 20px;
    }

    .priceInfo {
      width: auto;
      height: 30px;
      margin-top: 15px;
      display: flex;
      align-items: flex-end;

      .priceName {
        font-size: 25px;
        font-family: Roboto-ThinItalic, Roboto;
        font-weight: 300;
        color: #000000;
        margin-right: 16px;
      }

      .priceLogo {
        display: block;
        width: 30px;
        height: 30px;
      }

      .priceNum {
        font-size: 25px;
        font-family: Roboto-BlackItalic, Roboto;
        font-weight: normal;
        color: #000000;
        margin-right: 16px;
      }

      .PriceUsaNum {
        font-size: 14px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
        position: relative;
        bottom: 5px;
      }
    }

    .collectionDescInfo {
      width: 400px;
      height: auto;
      margin-top: 22px;
      padding: 25px 20px 1px 14px;
      border: 1px dashed #979797;

      .descInfoCom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
        height: 16px;
        font-size: 14px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;

        .token-id {
          width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
        }

        .linkInfo {
          display: flex;
          align-items: center;

          .linkShare {
            display: block;
            width: 16px;
            height: 16px;
            margin-left: 3px;
          }
        }
      }
    }

    .buttonGroup {
      display: flex;
      align-items: center;
      margin-top: 22px;

      .buttoncom {
        width: 140px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;

        .shoppingImg {
          display: block;
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }

        .buynowImg {
          display: block;
          width: 25px;
          height: 25px;
          margin-right: 16px;
        }
      }

      .addCart {
        border: 1px solid #8636cd;
        font-size: 14px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #000000;
        margin-right: 50px;
        cursor: pointer;
      }

      .buyNow {
        background: #8636cd;
        font-size: 14px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}
