.activityInfo {
  width: 100%;
  height: auto;
  margin-top: 36px;

  .activeHeader {
    width: auto;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(210, 204, 204, .5);

    .actitle {
      flex: 1;
      font-size: 20px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #000000;
      line-height: 64px;
    }
  }

  .activity-table {
    width: 100%;
    height: auto;

    tr {
      height: 58px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #D2CCCC;

      th,
      td {
        width: 250px;
        height: 58px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
        line-height: 58px;
        text-align: left;
        letter-spacing: -0.34px;

        &:last-child {
          width: 136px;
        }
      }

      th {
        font-size: 16px;
      }

      td {
        font-size: 14px;
        display: flex;
        align-items: center;

        .eventLogo {
          display: block;
          width: 25px;
          height: 25px;
          margin-right: .07rem;
        }

        .coinIcon {
          display: block;
          width: 45px;
          height: 45px;
          margin-right: .12rem;
        }

        .priceIcon {
          margin-right: .06rem;
          display: block;
          width: 16px;
          height: 16px;
        }

        .shareImg {
          display: block;
          width: 16px;
          height: 16px;
          margin-left: .07rem;
        }

        .linkInfo {
          display: flex;
          align-items: center;
        }
      }
    }

    .nodata {
      width: 100%;
      font-size: 14px;
      color: #aaa;
      justify-content: center;
      margin: 30px 0;
      border: 0
    }
  }

  .addmores {
    width: 100px;
    height: 40px;
    border-radius: 23px;
    border: 1px solid rgba(134, 54, 205, 1);
    margin: 60px auto;
    font-size: 14px;
    font-family: Roboto-Light, Roboto;
    font-weight: 300;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
