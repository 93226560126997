.payMainPage {
  height: auto;
  width: 100%;

  // padding-bottom: 40px;
  .contents {
    min-height: calc(100vh - 125px);
  }

  .payContent {
    width: 1285px;
    min-height: 450px;
    margin: 30px auto 40px;
    border-radius: 20px;
    border: 1px solid #8636CD;
    display: flex;
    overflow: hidden;

    .myCoinList {
      width: 616px;
      height: auto;
      border-right: 1px dashed rgba(203, 197, 197, .5);

      .searchGroup {
        width: 100%;
        padding: 20px 0 0 52px;

        h3 {
          height: 16px;
          font-size: 14px;
          font-family: Roboto-BlackItalic, Roboto;
          font-weight: normal;
          color: #000000;
          line-height: 16px;
        }

        .sdesc {
          width: auto;
          height: 14px;
          font-size: 12px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #000000;
          line-height: 14px;
          margin-top: 16px;
        }

        .searchInput {
          display: block;
          width: 495px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid rgba(151, 151, 151, .1);
          margin-top: 16px;
          padding-left: 90px;
          font-size: 12px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #CBC5C5;
          background: transparent;

          &::placeholder {
            font-size: 12px;
            font-family: Roboto-Light, Roboto;
            font-weight: 300;
            color: #CBC5C5;
          }
        }
      }

      .resulyList {
        width: 100%;
        padding: 0 40px 0 24px;
        margin-top: 19px;
        max-height: 600px;
        overflow: auto;

        .selectList {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

        }
      }
    }


    .selectPayList {
      flex: 1;
      padding: 20px 60px 30px;

      .shoppingList {
        width: 100%;

        .selectedName {
          width: 100%;
          height: 46px;
          font-size: 14px;
          font-family: Roboto-BlackItalic, Roboto;
          font-weight: normal;
          color: #000000;
          line-height: 46px;
          border-bottom: 1px solid rgba(234, 235, 240, .5);
        }
      }

      .payWith {
        height: 54px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 26px 0 13px;

        .payName {
          font-size: 14px;
          font-family: Roboto-BlackItalic, Roboto;
          font-weight: normal;
          color: #000000;
        }

        .select {
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 5px;

          .iconInfo {
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }

          .selectListOptions {
            border: 0;
            height: auto;
            min-width: 150px;
            background: transparent;

            .css-1pahdxg-control,
            .css-1pahdxg-control:hover,
            .css-1s2u09g-control {
              border-width: 0;
              background-color: transparent;
              border-color: transparent;
              color: #494444;
              font-weight: 300;

              .css-319lph-ValueContainer {
                min-width: 50px;
                color: #494444;
                font-weight: 300;

                .css-14el2xx-placeholder {
                  color: #494444;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }

            .css-1okebmr-indicatorSeparator {
              width: 0;
            }

            .css-tlfecz-indicatorContainer {
              color: rgba(134, 54, 205, 1);
            }

            .css-1s2u09g-control,
            .css-1pahdxg-control {
              border: 0;
              background: transparent;
              padding: 0;

              &.active {
                border: 0;
              }
            }

            .css-tlfecz-indicatorContainer,
            .css-1gtu0rj-indicatorContainer {
              padding: 2px;
            }

            .css-6j8wv5-Input {
              min-width: 20px;
              color: #494444;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
