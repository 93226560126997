.projectInfo {
  width: 100%;
  height: 200px;
  padding: 0 1.4rem;
  margin-top: 33px;
  display: flex;
  justify-content: space-between;

  .projectInfoLeft {
    display: flex;

    .projectLogo {
      display: block;
      width: 200px;
      height: 200px;
      margin-right: .52rem;
    }

    .detailInfo {
      width: auto;
      height: 200px;
      padding-top: 37px;

      .projectTitle {
        width: auto;
        height: 35px;
        font-size: 30px;
        font-family: Roboto-BlackItalic, Roboto;
        font-weight: normal;
        color: #8636CD;
        line-height: 35px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .bigrz {
          display: block;
          width: 30px;
          height: 30px;
          margin-left: 17px;
        }
      }

      .projectDesc {
        width: 3.99rem;
        height: 36px;
        font-size: 14px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
        line-height: 18px;
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .imgList {
        width: auto;
        display: flex;

        .hreflink {
          display: block;
          width: 35px;
          height: 35px;
          margin-right: 27px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          img {
            display: block;
            width: 35px;
            height: 35px;
          }
        }

      }
    }
  }

  .projectInfoRight {
    width: auto;
    text-align: center;
    padding-top: 73px;

    .numList {
      width: 6rem;
      height: 80px;
      border-radius: 50px;
      border: 1px solid rgba(134, 54, 205, 1);
      display: flex;
      padding: 16px 10px 0 5px;

      li {
        flex: 1;
        text-align: center;

        .num {
          width: 100%;
          height: 24px;
          font-size: 20px;
          font-family: Roboto-BlackItalic, Roboto;
          font-weight: normal;
          color: #000000;
          line-height: 24px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;

          .iconlogo {
            display: block;
            width: 20px;
            height: 20px;

          }
        }

        .listName {
          width: 100%;
          height: 16px;
          font-size: 14px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #000000;
          line-height: 16px;
        }
      }
    }

    .link {
      width: 6rem;
      margin-top: 17px;
      display: flex;
      justify-content: center;

      .linkInfo {
        width: auto;
        font-size: 14px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
        line-height: 16px;
        display: flex;
        align-items: center;

        .linkActive {
          width: auto;
          height: 16px;
          font-size: 14px;
          font-family: Roboto-BlackItalic, Roboto;
          font-weight: normal;
          color: #8636CD;
          line-height: 16px;
        }

        .mr2 {
          margin-right: 2px;
        }

        .linkShare {
          display: block;
          width: 16px;
          height: 16px;
          margin-left: 3px;
        }
      }

      .mr44 {
        margin-right: 44px;
      }
    }
  }
}
