.allPay {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 15px;

  .pay-desc {
    font-weight: normal;
    font-size: 14px;
    .pay-amount {
      color: #8636cd;
      font-size: 18px;
    }
  }

  .allPayTitle {
    width: 200px;
    font-size: 14px;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #000000;
    padding-left: 10px;
  }

  .allPayNum {
    width: 130px;
    text-align: center;
    font-size: 12px;
    font-family: RobotoSerifNormalRoman-Bold, RobotoSerifNormalRoman;
    font-weight: bold;
    color: #8636cd;
  }

  .nextPay {
    flex: 1;
    text-align: right;
    padding-right: 10px;

    p {
      width: 100%;
      font-size: 12px;
      font-family: Roboto-Light, Roboto;
      font-weight: 300;
      color: #000000;
      line-height: 14px;
      margin-bottom: 5px;
      transform: scale(0.8);
    }
  }
}

.payButton {
  width: 530px;
  height: 35px;
  margin: 0 auto;
  background: #8636cd;
  border-radius: 25px;
  margin-top: 37px;
  text-align: center;
  line-height: 35px;
  font-size: 20px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  loadings {
    width: 20px !important;
    height: 20px !important;
    margin-right: 5px;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: gray;
  }
}

.tipes {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-family: Roboto-Light, Roboto;
  font-weight: 300;
  color: #000000;
  margin-top: 30px;

  .tipesDesc {
    width: 376px;
    height: 28px;
    line-height: 14px;
  }

  .feeGroup {
    flex: 1;
    margin-left: 20px;

    .feeNum {
      font-size: 12px;
      font-family: Roboto-Light, Roboto;
      font-weight: 300;
      color: #000000;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;

      .num {
        height: 14px;
        line-height: 14px;
        width: auto;
        text-align: right;
        font-size: 12px;
      }
    }
  }
}
