.collectionDetailPageMain {
  min-width: 1200px;
  min-height: 100vh;
  // padding-bottom: 100px;
  background: #f7f7f8;

  .imgLine {
    display: block;
    width: 100%;
    height: 82px;
  }

  .collectionContent {
    width: 1136px;
    min-height: calc(100vh - 125px);
    margin: 69px auto 100px;
  }
}
