.indexPageMain {
  width: 100%;
  height: auto;
  overflow: hidden;

  .allCollection {
    width: 160px;
    height: 40px;
    background: #8636CD;
    border-radius: 24px;
    margin: 42px auto 49px;
    font-size: 16px;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
  }
}
