.ProjetSynopsis {
  width: 100%;
  height: 100%;

  .projectimg {
    display: block;
    width: 3.35rem;
    height: 3.35rem;
    margin: 0 auto;
  }

  .projectTitle {
    width: 3.35rem;
    height: 43px;
    margin: 0 auto;
    border-bottom: 1px solid rgba(210, 204, 204, .3);
    padding-left: .19rem;
    line-height: 43px;
    font-size: 14px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #000000;
    overflow: hidden;
  }

  .priceInfo {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 .34rem 0 .19rem;

    .priceNum {
      width: 100%;
      font-size: 14px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #000000;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .shop {
      display: block;
      width: 20px;
      height: 20px;
    }

  }
}
