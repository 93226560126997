.properties {
  width: 100%;
  height: auto;
  margin-top: 70px;

  .title {
    width: auto;
    height: 64px;
    font-size: 20px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #000000;
    line-height: 64px;
    border-bottom: 1px solid rgba(210, 204, 204, .5);
  }

  .ulList {
    align-items: center;
    margin-top: 20px;
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fill, 203px);
    // height: 228px;
    overflow: hidden;

    li {
      flex-shrink: 0;
      width: 203px;
      height: auto;
      background: #F3F3FB;
      border-radius: 10px;
      border: 1px solid #738BD8;
      padding: 15px;
      margin-bottom: 20px;

      .dete {
        width: auto;
        height: 16px;
        font-size: 14px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
        line-height: 16px;
      }

      .unshaven {
        width: auto;
        height: 16px;
        font-size: 14px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #000000;
        line-height: 16px;
        margin-top: 7px;
      }

      .next {
        width: auto;
        height: 14px;
        font-size: 12px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
        line-height: 14px;
        margin-top: 9px;
      }
    }
  }

  .addMore {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .addMoreImg {
      display: block;
      width: 16px;
      height: 16px;

    }
  }
}
