.projectMainPage {
  width: 100%;
  min-height: 100vh;
  background: #f7f7f8;

  .bannerImg {
    width: 100%;
    height: auto;
    overflow: hidden;
    max-height: 250px;

    img {
      margin: 0 auto;
      display: block;
      object-fit: contain;
      width: 100%;
    }
  }

  .searchContent {
    width: 100%;
    height: auto;
    border-top: 1px solid rgba(151, 151, 151, .1);
    margin-top: 48px;
    display: flex;

    .Slider {
      width: 340px;
    }

    .listContent {
      flex: 1;
      min-height: 110vh;
      padding-bottom: 50px;

      .listContentHeader {
        height: 60px;
        width: 100%;
        border-bottom: 1px solid rgba(151, 151, 151, .1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2.1rem 0 .35rem;

        .tablesgroup {
          display: flex;

          .items {
            width: 83px;
            height: 60px;
            font-size: 20px;
            font-family: Roboto-BlackItalic, Roboto;
            font-weight: normal;
            color: #000000;
            line-height: 60px;
            text-align: center;
            margin-right: 27px;
            cursor: pointer;

            &:last-child {
              margin-right: 0;
            }

            &.active {
              color: #8636CD;
              position: relative;

              &::after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: rgba(134, 54, 205, 1);
              }
            }
          }
        }

        .listSearchGroup {
          width: auto;
          display: flex;
          justify-content: flex-end;

          .inputInfo {
            display: flex;
            align-items: center;
          }

          .boxgroup {
            width: 192px;
            height: 30px;
            border-radius: 10px;
            border: 1px solid rgba(134, 54, 205, .1);
            margin-right: 26px;
            padding: 0 20px;
            font-size: 14px;

            &:last-child {
              margin-right: 0;
            }

            .search {
              display: block;
              width: 16px;
              height: 16px;
              margin-right: 3px;
            }

            .nameorIdInput {
              height: 100%;
              flex: 1;
              background: transparent;
              font-size: 12px;
              font-family: Roboto-Light, Roboto;
              font-weight: 300;
              color: #000;
              line-height: 100%;
              border: 0;

              &::placeholder {
                font-size: 12px;
                font-family: Roboto-Light, Roboto;
                font-weight: 300;
                color: #CBC5C5;
                line-height: 100%;
              }
            }
          }

          .selectGroup {
            padding: 0;
            background: transparent;
            box-sizing: border-box;
            color: #000;
            font-weight: normal;
            font-size: 12px;

            .css-1s2u09g-control,
            .css-1pahdxg-control {
              height: 28px;
              min-height: 28px;
              border: 0;
              background: transparent;
              padding: 0;

              &.active {
                border: 0;
              }
            }

            .css-319lph-ValueContainer {
              padding: 0 8px;
            }

            .css-tlfecz-indicatorContainer,
            .css-1gtu0rj-indicatorContainer {
              padding: 2px;
            }
          }
        }
      }

      .eventList {
        font-size: 14px;
      }

      .listpagina {
        margin-top: 20px;
      }

      .listProject {
        flex: 1;
        height: auto;
        padding: .72rem 20px 0rem;
        display: grid;
        justify-content: space-evenly;
        grid-template-columns: repeat(auto-fill, 3.5rem);
        grid-column-gap: 10px;

        .projectInfoList {
          width: 3.5rem;
          background: #FFFFFF;
          box-shadow: 0px 8px 15px 0px rgba(149, 156, 182, 0.15);
          border-radius: 10px;
          border: 1px solid #EAEBF0;
          padding-top: .2rem;
          margin-bottom: .50rem;
        }

      }

      .projectActiveInfo {
        flex: 1;
        height: auto;
        padding: 28px 0 0rem .52rem;

        .addmores {
          width: 100px;
          height: 40px;
          border-radius: 23px;
          border: 1px solid rgba(134, 54, 205, 1);
          margin: 60px auto;
          font-size: 14px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}
