.cartDialogContent {
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 67px;
  right: -96px;
  background: #FFFFFF;
  border-radius: 20px;
  max-height: calc(100vh-100px);
  // overflow-y: auto;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, .16);
  border: 1px solid #8636CD;


  .titleGroup {
    width: 100%;
    height: 49px;
    font-size: 16px;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #000000;
    border-bottom: 1px solid rgba(149, 156, 182, 0.15);
    padding: 0px 24px 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .closeImg {
      display: block;
      width: 16px;
      height: 16px;
    }
  }

  .buyInfo {
    width: 530px;
    height: auto;
    position: relative;

    .triangle {
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: -11px;
      right: 105px;
    }

    .buyContent {
      width: 100%;
      height: auto;
      padding: 10px 20px 20px;
      border-left: 1px solid rgba(149, 156, 182, 0.15);

      .headerList {
        height: 36px;
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(149, 156, 182, 0.15);

        li {
          width: auto;
          height: 36px;
          font-size: 14px;
          font-family: Roboto-BlackItalic, Roboto;
          font-weight: normal;
          color: #000000;
          line-height: 36px;

          .groupes {
            width: fit-content;
            text-align: center;

            .number {
              // width: 51px;
              height: 16px;
              font-size: 10px;
              font-family: RobotoSerifNormalRoman-Bold, RobotoSerifNormalRoman;
              font-weight: bold;
              color: #000000;
              line-height: 16px;
            }

            .clearAll {
              display: block;
              width: 14px;
              height: 14px;
              margin: 0 auto;
            }

            .name {
              height: 14px;
              font-size: 12px;
              font-family: Roboto-Light, Roboto;
              font-weight: 300;
              color: #6C7293;
              line-height: 14px;
              margin-top: 3px;
            }
          }

          .onenumber {
            padding-left: 15px;
          }

        }

        .itemsList {
          width: 210px;
          padding-left: 13px;
        }

        .priceList {
          width: 100px;
          padding-left: 8px;
        }

        .quantityList {
          width: 60px;
          // padding-left: 8px;
          text-align: center;
          display: flex;
          justify-content: center;
        }

        .clearList {
          flex: 1;
          display: flex;
          justify-content: center;
        }
      }

      .allInfo {
        height: 50px;
        padding-top: 7px;
      }

      .listContent {
        width: 100%;
        max-height: 400px;
        overflow-y: auto;
        border-radius: 6px;
        border: 0;
        // border-bottom: 1px solid rgba(151, 151, 151, .1) !important;

        li {
          width: 100%;
          height: 70px;
        }
      }

      .content {
        width: 100%;
        height: inherit;
        padding: 13px 12px 12px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(151, 151, 151, .1);

        li {
          display: flex;
          height: 100%;
        }

        .itemsContent {
          width: 200px;
          align-items: center;

          .infoLogo {
            display: block;
            width: 45px;
            height: 45px;
            margin-right: 7px;
          }

          .nameGroup {
            flex: 1;

            .title {
              width: auto;
              height: 14px;
              font-size: 12px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #000000;
              line-height: 14px;
              overflow: hidden;
            }

            .desc {
              display: flex;
              align-items: center;
              margin-top: 9px;
              height: 16px;
              font-size: 12px;
              font-family: Roboto-Light, Roboto;
              font-weight: 300;
              color: #000000;

              .rzimg {
                display: block;
                width: 16px;
                height: 16px;
                margin-left: 3px;
              }
            }
          }
        }

        .priceContent {
          width: 100px;

          .iconbg {
            display: block;
            width: 20px;
            height: 20px;
          }

          .pandNum {
            flex: 1;

            .pNum {
              width: auto;
              height: 20px;
              font-size: 12px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #000000;
              line-height: 20px;
            }

            .uNum {
              width: auto;
              height: 14px;
              font-size: 12px;
              font-family: Roboto-Light, Roboto;
              font-weight: 300;
              color: #000000;
              line-height: 14px;
              margin-top: 4;
            }

            .activeColor {
              color: #8636CD;
            }
          }
        }

        .quantityContent {
          width: 60px;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #000000;
        }

        .clearContent {
          flex: 1;
          align-items: center;
          justify-content: center;

          .delate {
            display: block;
            width: 16px;
            height: 16px;
          }
        }
      }

      .noData {
        min-height: 150px;
        line-height: 150px;
        text-align: center;
        font-size: 16px;
        color: #9b9b9b;
      }

      .payButton {
        width: 450px;
        height: 50px;
        background: #8636CD;
        border-radius: 25px;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #FFFFFF;
        margin: 10px auto 0;
      }
    }

  }
}
