.userMainPage {
  min-height: 100vh;
  width: 100%;
  padding-bottom: 40px;
}

.userInfo {
  width: 100%;
  height: 150px;
  margin-top: 69px;
  display: flex;
  align-items: center;
  padding: 0 0 0 2.23rem;

  .userLogo {
    display: block;
    width: 150px;
    height: 150px;
    margin-right: 40px;
  }

  .infoGroup {
    width: auto;
    height: auto;

    .addressName {
      width: auto;
      height: 47px;
      font-size: 40px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #8636CD;
      line-height: 47px;
    }

    .addressInfo {
      display: flex;
      align-items: center;
      width: auto;
      height: 24px;
      margin-top: 18px;
      font-size: 20px;
      font-family: Roboto-Light, Roboto;
      font-weight: 300;
      color: #000000;
      line-height: 24px;
      cursor: pointer;

      .shareAddress {
        display: block;
        width: 16px;
        height: 16px;
        margin-left: 13px;
      }
    }
  }
}

.userTabs {
  width: 16.37rem;
  margin: 40px auto 0;
  height: 60px;
  border-bottom: 1px solid rgba(151, 151, 151, .1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .37rem 0 .35rem;

  .tablesgroup {
    display: flex;

    .items {
      width: 83px;
      height: 60px;
      font-size: 20px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #000000;
      line-height: 60px;
      text-align: center;
      margin-right: 27px;
      cursor: default;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: #8636CD;
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: rgba(134, 54, 205, 1);
        }
      }
    }
  }

  // .listSearchGroup {
  //   width: auto;
  //   display: flex;
  //   justify-content: flex-end;

  //   .inputInfo {
  //     display: flex;
  //     align-items: center;
  //   }

  //   .boxgroup {
  //     width: 192px;
  //     height: 30px;
  //     border-radius: 10px;
  //     border: 1px solid rgba(134, 54, 205, .1);
  //     margin-right: 26px;
  //     padding: 0 20px;

  //     &:last-child {
  //       margin-right: 0;
  //     }

  //     .search {
  //       display: block;
  //       width: 16px;
  //       height: 16px;
  //       margin-right: 3px;
  //     }

  //     .nameorIdInput {
  //       height: 100%;
  //       flex: 1;
  //       background: transparent;
  //       font-size: 12px;
  //       font-family: Roboto-Light, Roboto;
  //       font-weight: 300;
  //       color: #CBC5C5;
  //       line-height: 100%;
  //       border: 0;

  //       &::placeholder {
  //         font-size: 12px;
  //         font-family: Roboto-Light, Roboto;
  //         font-weight: 300;
  //         color: #CBC5C5;
  //         line-height: 100%;
  //       }
  //     }
  //   }
  // }
}

.UserListProject {
  width: 16.37rem;
  margin: 38px auto 0;
  padding: 0 0 0 .41rem;

  .projectList {
    display: flex;
    flex-flow: wrap;
  }

  .content {
    flex-shrink: 0;
    width: 3.5rem;
    min-height: 4.5rem;
    background: #FFFFFF;
    box-shadow: 0px 8px 15px 0px rgba(149, 156, 182, 0.15);
    border-radius: 10px;
    border: 1px solid #EAEBF0;
    padding-top: .2rem;
    margin-bottom: 50px;
    margin-right: .49rem;
  }
}

.activeInfo {
  width: 16.37rem;
  margin: 0 auto;
}

.addmores {
  width: 100px;
  height: 40px;
  border-radius: 23px;
  border: 1px solid rgba(134, 54, 205, 1);
  margin: 60px auto;
  font-size: 14px;
  font-family: Roboto-Light, Roboto;
  font-weight: 300;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
