.collections {
  width: 100%;
  height: auto;
  padding: 0 1.4rem;
  margin-top: 21px;

  h2 {
    width: auto;
    font-size: .50rem;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #000000;
    line-height: .58rem;
  }

  .collections-table {
    margin-top: .40rem;
    width: 100%;
    height: auto;

    tr {
      height: 70px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #D2CCCC;

      th,
      td {
        width: auto;
        height: 70px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
        line-height: 70px;
        text-align: left;
        letter-spacing: -0.34px;
      }

      th {
        font-size: 20px;
        font-family: Roboto-BlackItalic, Roboto;
      }

      td {
        font-size: 14px;
        display: flex;
        align-items: center;
        font-family: Roboto-Bold, Roboto;

        .iconlogo {
          display: block;
          width: 45px;
          height: 45px;
          margin-right: 12px;
          border-radius: 50%;
          overflow: hidden;
        }

        .name {
          max-width: 260px;
          height: 58px;
          line-height: 58px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Roboto-BlackItalic;
        }

        .rzone {
          display: block;
          width: 16px;
          height: 16px;
          margin-left: 6px;
        }

        .addnum {
          font-size: 12px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #17B449;
          margin-left: 17px;
        }

        .less {
          color: #BC3648;
        }

        .coinIcon {
          margin-right: 6px;
          display: block;
          width: 16px;
          height: 16px;
        }
      }

      .tableone {
        width: 30px;
      }

      .noindex {
        width: 0;
      }

      .tabletwo {
        width: 35%;

        .addLink {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }

      .tableother {
        width: 12%;
      }
    }
  }
}
