.activity {
  width: 100%;
  height: auto;

  .activity-table {
    width: 100%;
    height: auto;

    tr {
      height: 58px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #D2CCCC;

      th,
      td {
        width: auto;
        height: 58px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
        line-height: 58px;
        text-align: left;
        letter-spacing: -0.34px;
      }

      th {
        font-size: 16px;
      }

      td {
        font-size: 14px;
        display: flex;
        align-items: center;

        .linkInfo {
          width: 100%;
          display: flex;
          align-items: center;
        }

        .eventLogo {
          display: block;
          width: 25px;
          height: 25px;
          margin-right: .07rem;
        }

        .coinIcon {
          display: block;
          width: 45px;
          height: 45px;
          margin-right: .12rem;
        }

        .priceIcon {
          margin-right: .06rem;
          display: block;
          width: 16px;
          height: 16px;
        }

        .shareImg {
          display: block;
          width: 16px;
          height: 16px;
          margin-left: .07rem;
        }
      }

      .tableone {
        width: 10.5%;
      }

      .tabletwo {
        width: 20.8%;

        .names {
          display: block;
          width: 100%;
          height: 58px;
          line-height: 58px;
          overflow: hidden;
        }
      }

      .tableThree {
        width: 11%;
      }

      .tableFour {
        width: 11.3%;
      }

      .tableFive {
        width: 17.6%;
      }

      .tableSix {
        width: 14%;
      }

      .tableSeven {
        width: 14.5%;
      }
    }
  }
}
