.comfooter {
  width: 100%;
  height: 120px;
  background: linear-gradient(to right, rgba(243, 70, 128, 1), rgba(68, 128, 248, 1));
  position: relative;
  padding: 0 1.4rem;

  .footer-info {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    height: 120px;
    z-index: 10;

    .footer-logo {
      display: block;
      width: 239px;
      height: 120px;
    }

    .footer-right {
      width: auto;
      height: 100%;
      // padding-top: 78px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .hreflink {
        width: auto;
        font-size: 18px;
        font-family: Roboto-BlackItalic, Roboto;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 21px;
        margin-right: .33rem;
        z-index: 11;
        position: relative;
        top: 20px;
      }


    }

  }

  .bgimg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: url('../assets/img/footerimg.png')no-repeat;
    background-size: cover;
    z-index: 1;
  }
}
