.inputgroup {
  width: 500px;
  height: 50px;
  border-radius: 30px;
  border: 1px solid rgba(134, 54, 205, 1);
  display: flex;
  align-items: center;
  padding-left: 36px;
  position: relative;

  .iconName {
    display: block;
    height: 16px;
    width: 16px;
  }

  .headerInput {
    flex: 1;
    height: 100%;
    border: 0;
    background: transparent;
    font-size: 14px;
    font-family: Roboto-Light, Roboto;
    font-weight: 300;
    color: #000;
    line-height: 100%;
    padding-left: 13px;

    &::placeholder {
      font-size: 14px;
      font-family: Roboto-Light, Roboto;
      font-weight: 300;
      color: #CBC5C5;
    }
  }

  .searchDialog {
    visibility: hidden;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 7px 0 44px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, .16);

    .resultMoudle {
      width: 100%;
      height: auto;

      .moudleTitle {
        width: 100%;
        height: 46px;
        font-size: 16px;
        font-family: Roboto-BlackItalic, Roboto;
        font-weight: normal;
        color: #000000;
        line-height: 46px;
        border-bottom: 1px solid #EAEBF0;
        padding-left: 15px;
      }

      .resultList {
        padding: 20px 20px 0;
        display: flex;
        flex-flow: wrap;
        max-height: 355px;
        overflow: hidden;

        .listInfo {
          width: 50%;
          height: 45px;
          margin-bottom: 9px;
          display: flex;
          align-items: center;
          flex-shrink: 0;
          font-size: 20px;
          cursor: pointer;

          .icLogo {
            display: block;
            width: 45px;
            height: 45px;
            margin-right: 12px;
          }

          .icName {
            width: auto;
            height: 16px;
            font-size: 14px;
            font-family: Roboto-Light, Roboto;
            font-weight: 300;
            color: #000000;
            line-height: 16px;
          }

          .icNamegroup {
            width: auto;
            height: 45px;

            .tname {
              width: auto;
              height: 22px;
              font-size: 12px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #000000;
              line-height: 22px;
              overflow: hidden;
            }

            .cname {
              display: flex;
              align-items: center;
              width: 108px;
              height: 23px;
              font-size: 12px;
              font-family: Roboto-Light, Roboto;
              font-weight: 300;
              color: #000000;

              .labels {
                display: inline-block;
                max-width: 130px;
                height: 23px;
                line-height: 23px;
                overflow: hidden;
              }

              .rzimg {
                display: block;
                width: 14px;
                height: 14px;
                margin-left: 4px;
              }
            }
          }

          .rzimg {
            display: block;
            width: 14px;
            height: 14px;
            margin-left: 6px;
          }
        }

        .itemsInfo {
          margin-bottom: 23px;
        }
      }

      .itemslist {
        max-height: 498px;
      }

      .morelist {
        max-height: 2000px;
      }

      .addmore {
        width: 100%;
        height: 46px;
        font-size: 14px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .moreicon {
          display: block;
          width: 14px;
          height: 14px;
          transform: rotate(180deg);
          margin-left: 8px;
        }
      }

      .hideaddmore {
        height: 0;
        overflow: hidden;
      }
    }
  }

  .showdialog {
    visibility: visible;
  }
}
