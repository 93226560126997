.formGroup {
  position: relative;

  .selectMax {
    width: 21px;
    height: 45px;
    font-size: 10px;
    font-family: Roboto-Light, Roboto;
    font-weight: 300;
    color: #8636CD;
    line-height: 45px;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: 0px;
    right: 30px;
  }
}

.coinInfo {
  width: 485px;
  margin-left: 13px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 40px 0 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(151, 151, 151, .2);

  .leftCoinOnfo {
    display: flex;
    align-items: center;

    .listicon {
      display: block;
      width: 16px;
      height: 16px;
    }

    .group {
      width: auto;
      height: auto;
      margin-left: 14px;
      padding-top: 5px;

      .name {
        width: auto;
        height: 14px;
        font-size: 12px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
        line-height: 14px;
      }

      .desc {
        font-size: 12px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
      }
    }
  }

  .rightCoinInfo {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .numberInput {
      width: 110px;
      height: 25px;
      border-radius: 5px;
      border: 1px solid #8636CD;
      padding-left: 5px;
      color: rgba(134, 54, 205, 1);
    }
  }
}
