* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-decoration: none;
}

select:focus {
  outline: none
}

input {
  outline: none;
}

li {
  list-style-type: none;
}

.App {
  min-width: 1300px;
  min-height: 100vh;
  background: #f7f7f8;

  .mainContent {
    width: 100%;
    height: auto;
    // min-height: 100vh;
    padding-top: 125px;
    // padding-bottom: 120px;

  }
}
