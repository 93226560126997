.selectTokens {
  width: 100%;
  height: 196px;
  border-radius: 6px;
  border: 1px solid rgba(151, 151, 151, .1);
  padding: 5px 0;
  overflow: auto;

  .number {
    width: 100%;
    height: 16px;
    font-size: 10px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #8636CD;
    line-height: 11px;
  }

  .numberName {
    width: 100%;
    height: 9px;
    font-size: 8px;
    font-family: Roboto-Light, Roboto;
    font-weight: 300;
    color: #8636CD;
    line-height: 9px;
  }

  .listInfo {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(234, 235, 240, .5);
    padding-left: 10px;

    .leftContent {
      width: 135px;

      .numberContent {
        width: 34px;
        height: auto;
        text-align: center;
      }
    }

    .coinListLeft {
      display: flex;
      align-items: center;

      .coinLogo {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 20px;
      }

      .coinName {
        font-size: 12px;
        font-family: Roboto-Light, Roboto;
        font-weight: 300;
        color: #000000;
      }
    }

    .centerContent {
      width: 250px;
      text-align: center;
    }

    .coinPrice {
      font-size: 8px;
      font-family: Roboto-Light, Roboto;
      font-weight: 300;
      color: #000000;

      .banlance {
        margin: 0 10px;
      }
    }

    .rightContent {
      flex: 1;
      display: flex;
      justify-content: center;

      .selectClose {
        display: block;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .headerInfo {
    height: 35px;
    font-size: 14px;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #000000;
  }

  .totalInfo {
    color: rgba(134, 54, 205, 1);
  }
}
