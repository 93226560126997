.buyContent {
  width: 100%;
  height: auto;
  padding: 10px 0px 20px;
  // border-left: 1px solid rgba(149, 156, 182, 0.15);

  .headerList {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid rgba(149, 156, 182, 0.15);

    li {
      width: auto;
      height: 36px;
      font-size: 14px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #000000;
      line-height: 36px;

      .groupes {
        width: fit-content;
        text-align: center;

        .number {
          // width: 51px;
          height: 16px;
          font-size: 10px;
          font-family: RobotoSerifNormalRoman-Bold, RobotoSerifNormalRoman;
          font-weight: bold;
          color: #000000;
          line-height: 16px;
        }

        .clearAll {
          display: block;
          width: 14px;
          height: 14px;
          margin: 0 auto;
        }

        .name {
          height: 14px;
          font-size: 12px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #6C7293;
          line-height: 14px;
          margin-top: 3px;
        }
      }

      .onenumber {
        padding-left: 15px;
      }

    }

    .itemsList {
      width: 210px;
      padding-left: 13px;
    }

    .priceList {
      width: 130px;
      padding-left: 8px;
    }

    .quantityList {
      width: 60px;
      // padding-left: 8px;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    .clearList {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }

  .allInfo {
    height: 50px;
    padding-top: 7px;
    // border-bottom: 1px solid rgba(234, 235, 240, .5);
  }

  .listContent,
  .noList {
    width: 100%;
    max-height: 180px;
    overflow-y: auto;
    border-radius: 6px;
    border: 1px solid rgba(151, 151, 151, .5s);

    li {
      width: 100%;
      height: 54px;
    }
  }

  .content {
    width: 100%;
    height: inherit;
    padding: 10px 12px 10px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(151, 151, 151, .1);

    li {
      display: flex;
      height: 100%;
    }

    .itemsContent {
      width: 200px;
      align-items: center;

      .infoLogo {
        display: block;
        width: 35px;
        height: 35px;
        margin-right: 7px;
      }

      .nameGroup {
        flex: 1;

        .title {
          width: auto;
          height: 14px;
          font-size: 12px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #000000;
          line-height: 14px;
          overflow: hidden;
        }

        .desc {
          display: flex;
          align-items: center;
          margin-top: 3px;
          height: 16px;
          font-size: 12px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #000000;

          .rzimg {
            display: block;
            width: 16px;
            height: 16px;
            margin-left: 3px;
          }
        }
      }
    }

    .priceContent {
      // padding-top: 10px;
      width: 130px;

      .iconbg {
        display: block;
        width: 20px;
        height: 20px;
      }

      .pandNum {
        flex: 1;

        .pNum {
          width: auto;
          height: 20px;
          font-size: 12px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #000000;
          line-height: 20px;
        }

        .uNum {
          width: auto;
          height: 14px;
          font-size: 12px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #000000;
          line-height: 14px;
          margin-top: 4;
        }

        .activeColor {
          color: #8636CD;
        }
      }
    }

    .quantityContent {
      width: 60px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: Roboto-Light, Roboto;
      font-weight: 300;
      color: #000000;
    }

    .clearContent {
      flex: 1;
      align-items: center;
      justify-content: center;

      .delate {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
  }

  .noData {
    // min-height: inherit;
    text-align: center;
    font-size: 16px;
    color: #9b9b9b;
  }

  .payButton {
    width: 450px;
    height: 50px;
    background: #8636CD;
    border-radius: 25px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #FFFFFF;
    margin: 10px auto 0;
  }
}
