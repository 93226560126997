.introduction {
  width: 100%;
  height: 4rem;
  background: linear-gradient(135deg, #F34680 0%, #4480F8 100%);
  padding: 0 1.4rem;
  display: flex;
  justify-content: space-between;

  .introduction-left {
    width: auto;
    height: auto;
    padding-top: .62rem;

    h2 {
      width: 100%;
      font-size: .4rem;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #FFFFFF;
      line-height: .47rem;
      margin-bottom: .35rem;
      letter-spacing: -1px;
    }

    .pinfo {
      margin-bottom: .26rem;
      display: flex;
      align-items: center;

      img {
        display: block;
        width: .20rem;
        height: .20rem;
        margin-right: 12px;
      }

      p {
        width: auto;
        font-size: .2rem;
        font-family: Roboto-BlackItalic, Roboto;
        font-weight: normal;
        color: #FFFFFF;
        line-height: .24rem;
      }
    }
  }

  .introduction-right {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      width: auto;
      height: 76px;
      margin-right: .47rem;
      font-size: 14px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #CBC5C5;
      text-align: center;

      img {
        display: inline-block;
        width: 50px;
        height: 50px;
      }

      p {
        text-align: center;
        width: auto;
        height: 16px;
        line-height: 16px;
        margin-top: 10px;
      }

      .active {
        color: #fff;
      }
    }

    .more {
      width: 20px;
      text-align: left;
      padding-top: 17px;
    }
  }
}
